.phone-input {
    border: 0 !important;
    outline: none !important;
    width: 250px !important;
    padding-left: 55px !important;
    box-shadow: none !important;
}

.phone-dropdown {
    border: 0 !important;
    outline: none !important;
    background: transparent !important;
    padding-left: 15px !important;
}

.search-box {
    margin: 0;
    width: 95%;
}

.search-emoji {
    display: none;
}
